import React from "react";
import "./footer.scss";
import { NavLink } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { IoIosQrScanner, IoIosSettings } from "react-icons/io";

function Footer() {
  return (
    <div className="footer fixed-bottom">
      <nav className="navbar navbar-expand container bg-white">
        <ul className="navbar-nav w-100 row pt-2 pb-3">
          <li className="nav-item col">
            <NavLink
              className="nav-link py-0 d-flex flex-column justify-content-center align-items-center"
              to="/"
            >
              <FaHome size={28} color="#F29F7B" />
              Home
            </NavLink>
          </li>
          <li className="nav-item col">
            <NavLink
              className="nav-link py-0 d-flex flex-column justify-content-center align-items-center"
              to="/scan"
            >
              <IoIosQrScanner size={28} color="#F29F7B" />
              Scan
            </NavLink>
          </li>
          <li className="nav-item col">
            <NavLink
              className="nav-link py-0 d-flex flex-column justify-content-center align-items-center"
              to="/settings"
            >
              <IoIosSettings size={28} color="#F29F7B" />
              Einstellungen
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Footer;
