import React, { useEffect } from "react";
import config from "./config.json";
import Quagga from "quagga";
import "./scanner.scss";

const Scanner = (props) => {
  const { onDetected } = props;

  // initialize QuaggaJS and start looking for barcodes
  useEffect(() => {
    Quagga.init(config, (err) => {
      if (err) {
        console.log(err, "error msg");
      }
      Quagga.start();
      return () => {
        Quagga.stop();
      };
    });

    // detect barcode
    Quagga.onDetected(detected);
  });

  const detected = (result) => {
    onDetected(result.codeResult.code);
  };

  return <div id="interactive" className="viewport" />;
};

export default Scanner;
