import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer/footer";
import Scanner from "../components/Scanner/scanner";

function Scan() {
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  const onDetected = (result) => {
    setResult(result);
  };

  // navigate to corresponding product page as soon as barcode (result) is detected
  useEffect(() => {
    if (result) {
      navigate("/product", { state: { ean: result } });
    }
  }, [result, navigate]);

  return (
    <>
      <div className="scan">
        {
          // as long as there is no barcode (result) detected, keep scanning
          !result && <Scanner onDetected={onDetected} />
        }
      </div>
      <Footer />
    </>
  );
}

export default Scan;
