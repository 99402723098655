import React from "react";
import Footer from "../components/Footer/footer";

function Settings() {
  return (
    <>
      <div className="settings">
        <div className="container my-5 pb-5">
          <div className="row align-items-center px-2">
            <h1 className="font-weight-light">Einstellungen</h1>
            <p>
              Vielleicht kommen hier in Zukunft ein paar Einstellungen hinzu.
            </p>
            <p>
              Dieses Projekt wurde im Rahmen der Bachelorarbeit 2023 von
              Anna-Sophie Seidl umgesetzt. Da sich dieses Projekt noch in der
              Testphase befindet, dient dies lediglich als Hilfsmittel und
              Informationsquelle für Personen mit Fruktoseintoleranz, ersetzt
              jedoch nicht den Rat und die Empfehlungen von medizinischem
              Fachpersonal oder Ernährungsexperten! Es liegt in der
              Verantwortung des Benutzers, die Informationen der App kritisch zu
              prüfen und gegebenenfalls weitere Ressourcen zu konsultieren, um
              eine sichere und gesunde Ernährungsentscheidung zu treffen.
            </p>
            <p>
              Um die Produkte nach ihrer Fruktose-Verträglichkeit zu prüfen,
              wird in erster Linie die Zutatenliste unter die Lupe genommen.
              Befinden sich darunter Zutaten, welche fruktosehaltig sind, gilt
              dieses Produkt als "nicht verzehrbar". Auch die durchschnittlichen
              Nährwerte pro 100g fallen in diese Wertung mit ein. Solang sich
              der Zuckerwert unter 3g befindet, gilt dieses Produkt als
              "verzehrbar", da der Zucker (unteranderem auch Fruktose) in so
              geringen Mengen unbedenklich eingestuft wird. So kann es durchaus
              sein, dass ein Produkt, welches offensichtlich Fruktose
              beinhaltet, von diesem Projekt trotzdem als "verzehrbar"
              eingestuft wird.
            </p>
            <p>
              Als Datengrundlage für die weiteren Informationen zu den einzelnen
              Produkten wird die API von{" "}
              <a
                href="https://world.openfoodfacts.org"
                target="_blank"
                rel="noreferrer"
              >
                https://world.openfoodfacts.org
              </a>{" "}
              verwendet.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Settings;
