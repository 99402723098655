import React from "react";
import logo from "../logo.svg";

function Home() {
  return (
    <>
      <div className="home">
        <div className="container container-fullHeight text-center pt-5 pb-4">
          <div>
            <img className="pb-4 logo w-75" src={logo} alt="Fruktose Scanner" />
            <p className="container-small">
              Der Fruktose Scanner eignet sich für Personen, die an
              Fruktose-Malabsorption leiden. Diese App hilft dir,
              herauszufinden, ob in einem Produkt Fruktose drinnen ist. Dazu
              musst du nur den Barcode des Produkts scannen und die App erledigt
              den Rest.
            </p>
          </div>

          <div className="container-small">
            <div className="card border-0 p-3 shadow-lg text-center height-35">
              <div className="overflow-auto">
                <p className="card-heading">Haftungsaussage</p>
                <p className="card-text">
                  Dieses Projekt wurde im Rahmen der Bachelorarbeit an der
                  Fachhochschule Salzburg entwickelt. Das Ziel des Projekts war
                  es, eine mobile Anwendung zu erstellen, die Personen mit
                  Fruktoseintoleranz dabei unterstützt, schnell zu erkennen,
                  welche Produkte sie bedenkenlos verzehren können und welche
                  vermieden werden sollten.
                  <br />
                  <br />
                  Es ist wichtig zu beachten, dass die Fachhochschule Salzburg
                  in Bezug auf die Funktionalität und Richtigkeit der
                  Entscheidungen, die die App trifft, keine Haftung oder
                  Garantie übernimmt. Obwohl angemessene Anstrengungen
                  unternommen wurden, um die App genau und zuverlässig zu
                  gestalten, können Fehler oder Ungenauigkeiten auftreten. Die
                  App basiert auf einer Datenbank mit Produktinformationen und
                  Entscheidungsregeln, die von der Studentin recherchiert und
                  implementiert wurden. Es besteht jedoch die Möglichkeit, dass
                  einige Produkte möglicherweise nicht korrekt bewertet werden
                  oder dass individuelle Unterschiede bei der Verträglichkeit
                  auftreten können.
                  <br />
                  <br />
                  Die App dient als Hilfsmittel und Informationsquelle für
                  Personen mit Fruktoseintoleranz, ersetzt jedoch nicht den Rat
                  und die Empfehlungen von medizinischem Fachpersonal oder
                  Ernährungsexperten! Es liegt in der Verantwortung des
                  Benutzers, die Informationen der App kritisch zu prüfen und
                  gegebenenfalls weitere Ressourcen zu konsultieren, um eine
                  sichere und gesunde Ernährungsentscheidung zu treffen.
                  <br />
                  <br />
                  Die Bachelorarbeit und die mobile Anwendung sollen als Beitrag
                  zur Verbesserung des Alltags von Personen mit
                  Fruktoseintoleranz dienen und als Ausgangspunkt für weitere
                  Diskussionen und Entwicklungen auf diesem Gebiet dienen.
                </p>
              </div>
            </div>
          </div>

          <a
            className="btn-scan btn btn-lg btn-primary py-3 text-white text-uppercase"
            href="/scan"
            role="button"
          >
            Barcode scannen
          </a>
        </div>
      </div>
    </>
  );
}

export default Home;
