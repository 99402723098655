import React from "react";
import Footer from "../components/Footer/footer";

function NoPage() {
  return (
    <>
      <div className="nopage">
        <div className="container text-center pt-5 pb-3">
          <div className="row">
            <h1 className="font-weight-light mb-5">Keine Seite gefunden</h1>
            <p>
              Diese Seite existiert nicht. Kehre zurück zum{" "}
              <a
                className="btn btn-scan btn-primary text-white text-uppercase"
                href="/scan"
              >
                Scan
              </a>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default NoPage;
