import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/footer";
import { GrClose } from "react-icons/gr";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

function Product() {
  const location = useLocation();
  const { ean } = location.state;

  // --- GET PRODUCT FROM API ---
  // get only relevant fields from API
  const url = `https://world.openfoodfacts.org/api/v2/product/${ean}?fields=ingredients,ingredients_tags,nutriments,ingredients_text_de,image_front_small_url,brands,generic_name`;
  const [product, setProduct] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [hasFailed, setFail] = useState(false);

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        setProduct(response.data.product);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setFail(true);
      });
  }, [url]);

  // dislay a loading spinner
  if (isLoading) {
    return (
      <div className="container container-fullHeight-footer">
        <div className="text-center">
          <RotatingLines
            strokeColor="#F29F7B"
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    );
  }

  // dislay failure when product does not exist in API database
  if (hasFailed) {
    return (
      <>
        <div className="scan">
          <div className="container py-3">
            <a href="/scan">
              <GrClose size={25} />
            </a>
          </div>
          <div className="container container-fullHeight-footer px-3">
            <div className="card p-3 shadow-lg text-center">
              <h1 className="card-heading pb-3">
                Dieses Produkt ist leider nicht in der Datenbank hinterlegt!
              </h1>
              <p className="card-text">
                Somit kann hier keine Aussage getroffen werden.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  // --- FRUCTOSE CHECK ---
  let containsFructose;
  let backgroundColor;
  let ingredients;
  // check if API stores ingredients for this product
  const hasIngredients = product.ingredients ? true : false;

  // if so, check the product ingredients for products containing fructose
  if (product.ingredients) {
    // look for keywords
    const incompatibleIngredients =
      /(sugar)|(zucker)|(fructose)|(fruktose)|(disaccharide)|(honey)|(honig)|(e953)|(e965)|(e421)|(e420)/;
    containsFructose =
      incompatibleIngredients.test(product.ingredients_tags) &&
      product.nutriments.sugars_100g > 3; // also check the amount of sugar per 100g serving

    ingredients = product.ingredients_text_de.replaceAll("_", "");

    // set background color depending on whether the product contains fructose or not
    backgroundColor = containsFructose ? "#ffcccb" : "#ddfada";
  } else {
    // else, let the user know, that there are no informations on the ingredients provided
    ingredients = "Bei diesem Produkt sind keine Inhaltsstoffe bekannt";
    backgroundColor = "#fff";
  }

  return (
    <>
      <div className="scan">
        <div
          className="container sticky-top py-3"
          style={{ backgroundColor: "#F7F2EF" }}
        >
          <a href="/scan">
            <GrClose size={25} />
          </a>
        </div>
        <div className="container container-fullHeight-footer mb-5 pb-5 px-3">
          <div className="card border-0 p-3 mb-3 shadow-lg text-center">
            {product.image_front_small_url && (
              <img
                src={product.image_front_small_url}
                alt="shows what the product looks like"
                className="object-fit-contain mx-auto d-block mb-2"
              ></img>
            )}
            {product.brands && (
              <h1 className="card-heading">{product.brands}</h1>
            )}
            {product.generic_name && (
              <p className="card-text pb-2">{product.generic_name}</p>
            )}

            <div
              className="card p-3 mt-2 shadow text-center"
              style={{ backgroundColor: backgroundColor }}
            >
              <h3 className="card-subheading">Inhaltsstoffe</h3>
              <p className="card-text">{ingredients}</p>
            </div>
          </div>

          {hasIngredients ? (
            containsFructose ? (
              <p className="text-danger h4 text-center">
                <b>Dieses Produkt enthält Fruktose!</b>
              </p>
            ) : (
              <p className="text-success h4 text-center">
                <b>Dieses Produkt enthält keine/kaum Fruktose!</b>
              </p>
            )
          ) : (
            <p className="h4 text-center">
              <b>Zu diesem Produkt kann keine Aussage getroffen werden!</b>
            </p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Product;
